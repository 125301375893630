import React from "react";
import Seo from "../components/seo/index";
import Layout from "../layout/index";
import SuccessScreen from "../components/sharedComponents/successScreen";

export default function Success() {
  return (
    <>
      <div>
        <Seo title="Green Heartfulness Run 2024 - Payment Successful" />
        <Layout isSticky>
          <section>
            <SuccessScreen />
          </section>
        </Layout>
      </div>
    </>
  );
}
