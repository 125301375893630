import React from "react";
import Breadcrumbsbanner from "./breadcumbbanner/breadcumbsbanner";
import backn from "../../assets/images/backIconSummary.svg";
// import logOutimg from "../../assets/images/logOut.png";
import { navigate } from "gatsby";
import { useEffect } from "react";
import KanhaRunService from "../services/kanha.service";
import { Button } from "react-bootstrap";
import { lStorage } from "../utils/storage";

export default function SuccessScreen() {
  const localtionDetails =
    typeof window !== "undefined" && window.location
      ? window.location.href
      : "";

  const kanhaApi = new KanhaRunService();

  const query = new URLSearchParams(localtionDetails);

  const pnr = query.get("pnr");
  const transaction_id = query.get("payment_order_id");
  const status = query.get("status");

  const backToParticipant = () => {
    navigate("/myregisterationlist");
    lStorage.remove("list");
    lStorage.remove("appliedCouponCode");
  };
  // const logOut = () => {
  //   lStorage.clear();
  //   navigate("/");
  // };
  const reloadPage = (payLoad) => {
    kanhaApi
      .transactionupdate(payLoad)
      .then((response) => {
        if (
          response.data.isError === false ||
          response.data.statusCode === "200"
        ) {
          if (response.data.isError === false) {
            // window.open(response.data.message.paymentUrl, "_blank");
          } else {
            // console.log("error");
          }
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  useEffect(() => {
    const payLoad = {
      pnr_no: pnr,
      status: status,
      transaction_id: transaction_id,
    };

    reloadPage(payLoad);
  }, []);

  return (
    <>
      <Breadcrumbsbanner />
      <div className="succesfulcards-wrapper inner-pages p-4">
        <div className="container-fluid" style={{ marginBottom: "200px" }}>
          <div className="row">
            <div className="p-2">
              <Button
                className="btn btn-primary"
                style={{ margin: "0 12px" }}
                onClick={backToParticipant}
              >
                <span onClick={backToParticipant} className="hand">
                <img className="img-fluid" src={backn} alt="back" />
              </span>{" "}
              Back to My Registration
              </Button>{" "}
              {/* <Button
                className="btn btn-primary p-1"
                style={{ float: "right" }}
                onClick={logOut}
              >
                Logout{" "}
                <img
                  className="img-fluid"
                  src={logOutimg}
                  width="15px"
                  alt="Clock logo"
                />
              </Button>{" "} */}
            </div>
            <div className="col-md-12">
              <div className="succesfulcards-wrapper-box">
                <br />
                <div className="pnrnumber-details">
                  {/* <h4> PNR 1234567 </h4> */}
                  &nbsp;
                  <h2>
                    {" "}
                    <b>Payment Successful</b>
                  </h2>
                </div>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12" style={{ margin: "20px" }}>
                      <h4>
                        <b>Thank you!</b> we have received your payment
                      </h4>
                      <p className="mob-fnt" style={{fontSize:"17px"}}>
                        You have successfully registered to{" "}
                        <b>Green Heartfulness RUN 2024</b>{" "}
                        with reference to the <b>{pnr}</b>
                      </p>
                      <p style={{fontSize:"17px"}}>
                        <b>Note : Your payment status will reflect in 5 minutes</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
